import { Compartment, EditorState } from '@codemirror/state'
import { EditorView, ViewPlugin, Decoration, keymap } from '@codemirror/view'
import { history } from '@codemirror/history'
import { defaultKeymap } from '@codemirror/commands'
// import GraphemeSplitter from 'grapheme-splitter'
import './styles.css'

const appContainer = document.querySelector('.app')
const editorContainer = document.querySelector('.editor-container')
const counterContainer = document.querySelector('.counter')
const lengthContainer = document.querySelector('.length')
const infoButton = document.querySelector('.info-button')
const infoContainer = document.querySelector('.info')
let counter = 0

infoButton.addEventListener('click', (event) => {
  event.preventDefault()
  infoContainer.style.display =
    infoContainer.style.display === 'none' ? 'block' : 'none'
})
infoButton.style.opacity = 1

const keyPromise = crypto.subtle.importKey(
  'jwk',
  {
    alg: 'A256GCM',
    ext: true,
    k: 'H7yvTlB5IShafAM-6AQBGxWOoNtiXS0lasvKOuw_Tsw',
    key_ops: ['encrypt', 'decrypt'],
    kty: 'oct',
  },
  { name: 'AES-GCM' },
  true,
  ['encrypt', 'decrypt']
)

// const splitter = new GraphemeSplitter()

decryptWord()
  .then((word) => {
    lengthContainer.textContent = word.length

    const charCounts = new Map()
    for (const char of word) {
      charCounts.set(char, (charCounts.get(char) || 0) + 1)
    }

    const completedConf = new Compartment()

    const extensions = [
      keymap.of(defaultKeymap),
      history(),
      completedConf.of([]),
      EditorState.transactionExtender.of((tr) => {
        if (tr.state.doc.toString().toUpperCase() === word) {
          return {
            effects: [
              completedConf.reconfigure([
                EditorView.editorAttributes.of({
                  class: 'complete',
                }),
                EditorView.editable.of(false),
              ]),
            ],
          }
        }
      }),
      ViewPlugin.define(
        () => {
          const value = {
            decorations: Decoration.none,
            update: (update) => {
              const guessCharCounts = new Map()
              const seen = new Set()

              // const guessChars = splitter.splitGraphemes(
              //   update.state.doc.toString().toUpperCase()
              // )

              const guessChars = update.state.doc
                .toString()
                .toUpperCase()
                .split('')

              const items = []

              guessChars.forEach((char, index) => {
                if (word[index] === char) {
                  seen.add(index)

                  guessCharCounts.set(
                    char,
                    (guessCharCounts.get(char) || 0) + 1
                  )

                  items.push(
                    Decoration.mark({ class: 'correct' }).range(
                      index,
                      index + 1
                    )
                  )
                }
              })

              guessChars.forEach((char, index) => {
                if (
                  !seen.has(index) &&
                  word.includes(char) &&
                  (guessCharCounts.get(char) || 0) < charCounts.get(char)
                ) {
                  seen.add(index)

                  guessCharCounts.set(
                    char,
                    (guessCharCounts.get(char) || 0) + 1
                  )

                  items.push(
                    Decoration.mark({ class: 'present' }).range(
                      index,
                      index + 1
                    )
                  )
                }
              })

              guessChars.forEach((char, index) => {
                if (!seen.has(index)) {
                  items.push(
                    Decoration.mark({ class: 'absent' }).range(index, index + 1)
                  )
                }
              })

              value.decorations = Decoration.set(items, true)
            },
          }
          return value
        },
        {
          decorations: (value) => value.decorations,
        }
      ),
    ]

    const doc = ''
    const state = EditorState.create({ doc, extensions })

    const view = new EditorView({
      state,
      dispatch: (tr) => {
        const transaction = view.state.update(tr)
        if (
          !transaction.docChanged ||
          transaction.state.doc.length <= word.length
        ) {
          view.update([transaction])
          if (transaction.docChanged) {
            counter++
            counterContainer.textContent = counter
          }
        }
      },
    })

    editorContainer.append(view.dom)
    appContainer.style.opacity = 1
    view.focus()
  })
  .catch((error) => {
    console.error(error)
  })

const iv = new Uint8Array([1, 2, 3])
const algorithm = { name: 'AES-GCM', iv }

window.generate = async (word) => {
  const data = new TextEncoder().encode(word)
  // const iv = crypto.getRandomValues(new Uint8Array(12))
  const key = await keyPromise
  const arraybuffer = await crypto.subtle.encrypt(algorithm, key, data)

  const ctArray = Array.from(new Uint8Array(arraybuffer)) // ciphertext as byte array
  const ctStr = ctArray.map((byte) => String.fromCharCode(byte)).join('') // ciphertext as string
  const ctBase64 = btoa(ctStr) // encode ciphertext as base64

  const url = new URL(window.location)
  url.searchParams.set('word', ctBase64)
  console.log(url.toString())
}

async function decryptWord() {
  const url = new URL(window.location)
  const encryptedWord = url.searchParams.get('word')
  if (!encryptedWord) return 'LATEX'
  const key = await keyPromise
  const ctStr = atob(encryptedWord)
  const ctUint8 = new Uint8Array(new ArrayBuffer(ctStr.length))
  for (let i = 0; i < ctStr.length; i++) {
    ctUint8[i] = ctStr.charCodeAt(i)
  }
  const arraybuffer = await crypto.subtle.decrypt(algorithm, key, ctUint8)
  const word = new TextDecoder().decode(arraybuffer)
  return word.toUpperCase()
}
